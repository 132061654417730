/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { mapOptions } from '../../../config/MapConfig';
import { Sync, NotSync } from '../../../constants/Icon';
import { DataContext } from '../../../context/DataContext';
import MapContext from '../../../context/MapContext';
import useMapTracking from '../../../hooks/useMapTracking';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { config } from '../../../config/Config';
import { handleMessage } from '../../../helper/utils';
import moment from 'moment';

const zoom = 13;

const MapDom = ({
  id,
  children,
  onDblClick,
  onDragEnd,
  onDragStart,
  onMouseDown,
  onMouseMove,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onRightClick,
  onClick,
  onDrag,
  onLoad,
  onUnmount,
  boundsChange,
  ...props
}) => {
  const ref = useRef();
  const { t } = useTranslation();

  const [map, setMap] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);
  const { isOpenFetchDataWhenZoom, onToggleSyncDataFilterMap, isLoadingFetchDataWhenZoom } =
    useContext(DataContext);

  const [customControls, setCustomControls] = useState([]);
  const [selectedShape, setSelectedShape] = useState(null);
  const [isExportExcel, setIsExportExcel] = useState(false);

  const {
    center,
    polygonCompleted,
    onChange,
    fitBoundsCenter,
    driverId,
    isRenderNewMaps,
    orders,
    polygon,
    drivers,
  } = useMapTracking();
  const driverIds = drivers.map((driver) => driver?.driver_id);
  const activeFitBounds = driverId ? true : false;
  const haveOrder = orders?.length > 0;

  const event = useRef(null);

  //set selection to a shape
  function setSelection(shape) {
    setSelectedShape(shape);
    shape.setEditable(true);
  }

  //delete selected shape
  function deleteSelectedShape() {
    polygonCompleted();
    if (!selectedShape) {
      setMap(null);
      return;
    }
    selectedShape.setMap(null);
    setSelectedShape(null);
  }

  const exportExcel = () => {
    setIsExportExcel(true);
    try {
      axios
        .post(
          config.API_URL.LOCATION.EXPORT_EXCEL_DRIVERS,
          {
            driverIds: driverIds,
          },
          {
            headers: {
              ...config.headers,
              'Content-Disposition': 'attachment; filename=template.xlsx',
              'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Excel-driver-${moment().locale('en').format('MM-DD-YYYY')}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          setIsExportExcel(false);
        });
    } catch (errors) {
      handleMessage('error', 'Export excel failed! Please export excel again.');
      setIsExportExcel(false);
    }
  };

  useEffect(() => {
    if (drawingManager === null) return;
    if (polygon.length === 0) {
      drawingManager.setOptions({
        drawingControl: true,
      });
    }
    if (polygon.length !== 0) {
      drawingManager.setOptions({
        drawingControl: false,
      });
    }
  }, [polygon, drawingManager]);

  useEffect(() => {
    const customs = React.Children.map(children, (child) => {
      if (React.isValidElement(child) && child.type.displayName === 'CustomControl') {
        return child;
      }
    });
    setCustomControls(customs);
  }, [children]);

  useEffect(() => {
    if (map && customControls.length) {
      map.controls[window.google.maps.ControlPosition.TOP_CENTER] = [];
      customControls.map((control) => {
        const container = window.document.createElement('div');
        ReactDOM.render(control, container);
        map.controls[window.google.maps.ControlPosition[control.props.position]].push(container);
        return null;
      });
    }
  }, [map, customControls]);

  useEffect(() => {
    if (ref.current && !map) {
      //
      const overlayComplete = 'overlaycomplete';
      const colorPolygon = '#be2448';
      const windowMaps = window.google.maps;
      const defaultPolygon = new windowMaps.Polygon({
        paths: polygon,
        editable: true,
        strokeColor: colorPolygon,
        fillColor: colorPolygon,
      });
      const windowDrawing = windowMaps.drawing;
      const curMap = new windowMaps.Map(ref.current, {
        ...mapOptions,
        ...props,
        center,
        zoom,
      });

      const drawingManager = new windowDrawing.DrawingManager({
        drawingMode: windowDrawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: windowMaps.ControlPosition.TOP_CENTER,
          drawingModes: [windowDrawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          editable: true,
          strokeColor: colorPolygon,
          fillColor: colorPolygon,
        },
        isDrawingModeEnabled: true,
      });
      if (polygon) {
        defaultPolygon.setMap(curMap);
      }

      setMap(curMap);

      setDrawingManager(drawingManager);
      drawingManager.setMap(curMap);
      drawingManager.setDrawingMode(null);
      windowMaps.event.addListener(drawingManager, overlayComplete, function (event) {
        // Switch to non-drawing mode after drawing a shape.
        drawingManager.setDrawingMode(null);
        var newShape = event.overlay;
        newShape.type = event.type;
        //after drawing = set selected
        setSelection(newShape);

        polygonCompleted(event);
      });
      if (polygonCompleted) {
        windowMaps.event.addListener(drawingManager, overlayComplete, function (event) {
          polygonCompleted(event);
        });
      }
    }
  }, [ref, map, props, polygonCompleted, customControls, selectedShape]);

  useEffect(() => {
    let listenerPolygonSet_at = null;
    let listenerPolygonInsert_att = null;

    if (selectedShape) {
      const windowMaps = window.google.maps;
      listenerPolygonSet_at = windowMaps.event.addListener(
        selectedShape.getPath(),
        'set_at',
        function () {
          const data = { overlay: selectedShape };
          polygonCompleted(data);
        }
      );
      listenerPolygonInsert_att = windowMaps.event.addListener(
        selectedShape.getPath(),
        'insert_at',
        function () {
          const data = { overlay: selectedShape };
          polygonCompleted(data);
        }
      );
    }
    return () => {
      if (listenerPolygonSet_at !== null) {
        listenerPolygonSet_at.remove();
      }
      if (listenerPolygonInsert_att !== null) {
        listenerPolygonInsert_att.remove();
      }
    };
  }, [selectedShape, drawingManager]);
  useEffect(() => {
    if (map) {
      const drawBtn = document.querySelector('[aria-label="Draw a shape"]');
      if (drawBtn) {
        drawBtn.addEventListener('click', () => {
          deleteSelectedShape();
        });
      }
    }
  }, [map, selectedShape]);

  const onChangeCallBack = useCallback(
    debounce((params) => onChange(params, isOpenFetchDataWhenZoom), 1000),
    [isOpenFetchDataWhenZoom]
  );

  useEffect(() => {
    let eventDrag = null;
    let eventZoomChange = null;
    if (map) {
      eventDrag = window.google.maps.event.addListener(map, 'dragend', () => {
        const bounds = getBounds(map);
        onChangeCallBack({ bounds });
      });
      eventZoomChange = window.google.maps.event.addListener(map, 'zoom_changed', () => {
        const bounds = getBounds(map);
        onChangeCallBack({ bounds });
        // if (event.current === null) {
        //   event.current = window.google.maps.event.addListener(map, 'zoom_changed', () => {
        //     console.log('zoom_changed event');
        //     const bounds = getBounds(map);
        //     onChangeCallBack({ bounds });
        //   });
        // }
      });
    }
    return () => {
      if (map) {
        if (eventDrag !== null) {
          eventDrag.remove();
        }
        if (eventZoomChange !== null) {
          eventZoomChange.remove();
        }
      }
      // eventZoomChange.remove();
    };
  }, [onChangeCallBack, map]);
  const removeEventZoomChange = () => {
    if (event?.current) {
      event?.current?.remove();
      event.current = null;
    }
  };

  useEffect(() => {
    if (window && window?.google?.maps && map && isRenderNewMaps) {
      const bounds = new window.google.maps.LatLngBounds();
      fitBoundsCenter?.map((item) => bounds.extend(item));
      if (!activeFitBounds) {
        removeEventZoomChange();
        // if (isAutoFitBounds) {
        //   map.fitBounds(bounds);
        // }
      } else {
        if (haveOrder) {
          removeEventZoomChange();
          // if (isAutoFitBounds) {
          //   map.fitBounds(bounds, paddingFitBounds);
          // }
        } else {
          // if (isAutoFitBounds) {
          //   map.fitBounds(bounds);
          //   map?.setZoom(zoomDoesHaveOrder);
          // }
        }
      }
    }
  }, [window, map, fitBoundsCenter, activeFitBounds, isRenderNewMaps, haveOrder]);

  const getBounds = (curMap) => {
    const _bounds = curMap?.getBounds();
    const ne = _bounds?.getNorthEast();
    const sw = _bounds?.getSouthWest();
    const bounds = {
      northLatitude: ne?.lat(),
      eastLongitude: ne?.lng(),
      southLatitude: sw?.lat(),
      westLongitude: sw?.lng(),
    };
    return bounds;
  };

  return (
    <>
      <MapContext.Provider value={map}>
        <div className="relative w-2/3">
          <div ref={ref} className="maps !w-full"></div>

          {polygon.length !== 0 && (
            <div className="absolute top-2 left-[45%]">
              <button
                disabled={isExportExcel}
                className={`refresh-button  ${
                  isExportExcel ? 'opacity-40 border-red-200' : 'opacity-100 '
                } `}
                onClick={exportExcel}
              >
                Download excel
              </button>
              <button
                type="button"
                onClick={deleteSelectedShape}
                className="bg-white px-2 py-[3px] rounded-md"
              >
                {t('delete_polygon')}
              </button>
            </div>
          )}
          <button
            disabled={isLoadingFetchDataWhenZoom}
            className={`absolute bottom-[200px] right-[10px]  w-[40px] h-[40px] rounded-[2px] flex justify-center items-center ${
              isOpenFetchDataWhenZoom ? 'bg-white' : 'bg-[#E1E1E1]'
            }`}
            onClick={onToggleSyncDataFilterMap}
          >
            <div className="w-[20px] h-[20px]">
              {isOpenFetchDataWhenZoom ? <Sync /> : <NotSync />}
            </div>
          </button>
        </div>

        {React.Children.map(children, (child) => {
          if (React.isValidElement(child) && child.type.displayName !== 'CustomControl') {
            return React.cloneElement(child, { map });
          }
        })}
      </MapContext.Provider>
    </>
  );
};

export default memo(MapDom);
