import axios from 'axios';
import produce from 'immer';
import { cloneDeep, debounce, isArray, isEmpty, isObject, isString } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTabs } from 'react-headless-tabs';
import { useHistory, useLocation } from 'react-router-dom';
import { config } from '../../../config/Config';
import { DataContext } from '../../../context/DataContext';
import { buildQueryString, parseQueryString } from '../../../helper/utils';
import { createRangeDate, newCreateRangeDate } from '../../../hooks/useDate';
import updateQuantities from './common/UpdateQuantities';
import useLayout from '../../../hooks/useLayout';
import generateRandomId from './common/GenerateRandomId';

export default function useNewOrder({
  isLoading,
  setIsLoading,
  currentPage,
  setCurrentPage,
  setData,
  setTotal,
  limit,
  setLimit,
  data,
}) {
  const [selectedTab, setSelectedTab] = useTabs(['order', 'fake_order']);
  const [selectedItem, setSelectedItem] = useState([]);
  const filtersRef = useRef({});
  const history = useHistory();
  const [firstLoadPage, setFirstLoadPage] = useState(false);
  const { currentTimezone } = useContext(DataContext);

  const { search } = useLocation();
  const queryString = parseQueryString(search);

  let revertFreeExpress;
  let totalOrderQ = 0;
  let totalQuantity = 0;
  let totalItemPrice = 0;
  let totalQuantityPrice = 0;
  let totalItemReOrder = 0;
  let totalToppingReOrder = 0;

  const { height, refHeight } = useLayout();

  const [cart, setCart] = useState([]);
  const [orderDataDetail, setOrderDataDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [newItemOrderDataDetail, setNewItemOrderDataDetail] = useState([]);
  const [quantityItemIncrease, setQuantityItemIncrease] = useState(1);
  const missingReOrder = orderDataDetail?.is_missing_items_order;
  const arr = orderDataDetail?.customer_coordinates?.split(',');

  cart?.forEach((item) => {
    totalOrderQ += parseInt(item.order_q);
    totalItemPrice += Number(item.item_price) * item.order_q;
    item?.options?.forEach((option) => {
      totalQuantity += option.quantity;
      totalQuantityPrice += Number(option?.price) * option?.quantity;
    });
  });

  orderDataDetail?.items?.forEach((item) => {
    totalItemReOrder += Number(item.item_price) * Number(item.item_quantity);
    item?.options?.forEach((option) => {
      totalToppingReOrder += Number(option?.price) * option?.quantity;
    });
  });
  const totalReOrderIsMissing = totalItemReOrder + totalToppingReOrder;

  const totalResult = totalOrderQ + totalQuantity;

  if (orderDataDetail?.freeExpress === true) {
    revertFreeExpress = 1;
  } else {
    revertFreeExpress = 0;
  }

  const newFoodItem = {
    // payment_type: orderDataDetail?.payment_type_shukah,
    offerId: null,
    restaurant_code: orderDataDetail?.restaurant_code,
    note: '',
    c_address: orderDataDetail?.customer_address,
    area: null,
    city: orderDataDetail?.customer_city,
    country: null,
    state: null,
    zipcode: null,
    lat: arr?.[0],
    lng: arr?.[1],
    delivery_method: 'expressDelivery',
    voucher_code: orderDataDetail?.voucher_code,
    usingCode: 0,
    freeExpress: revertFreeExpress,
    redeem_points: null,
  };

  const addItemToCart = (data) => {
    const option = data?.options?.map((item) => {
      const quantity = Number(item?.quantity) / Number(data?.item_quantity);
      const max_topping = Number(item?.quantity);
      const total_price = quantity * item.price;
      return {
        addon_id: item?.addon_id,
        id: item?.id,
        name: item?.name,
        price: item?.price,
        quantity: quantity,
        total_price: total_price,
        max_topping: max_topping,
        idToppingLocal: item?.idToppingLocal,
      };
    });
    const foodItem = {
      idItemLocal: data?.idItemLocal,
      item_name: data?.item_name,
      item_price: data?.item_price,
      order_q: '1',
      item_quantity: data?.item_quantity,
      options_en: data?.options_en,
      options_ids: data?.options_ids,
      order_number: data?.order_number,
      size_id: data?.sizeId || 0,
      sizeName: data?.sizeName,
      item_id: data?.item_id,
    };
    const finalFoodItem = { ...newFoodItem, ...foodItem };
    finalFoodItem.options = option;

    let isExisting = cart.some((item) => {
      return item.idItemLocal === finalFoodItem.idItemLocal;
    });
    if (!isExisting) {
      setCart([...cart, finalFoodItem]);
    }
  };

  const addTopingItemToCart = (data, topping) => {
    let Options = [];

    const quantity = Number(topping?.quantity) / Number(data?.item_quantity);
    const max_topping = Number(topping?.quantity);

    const newTopping = {
      ...topping,
      quantity: quantity,
      max_topping: max_topping,
    };

    Options.push(newTopping);

    const foodItem = {
      idItemLocal: data?.idItemLocal,
      item_name: data?.item_name,
      item_price: data?.item_price,
      order_q: '0',
      item_quantity: data?.item_quantity,
      options_en: data?.options_en,
      options_ids: data?.options_ids,
      order_number: data?.order_number,
      size_id: data?.sizeId,
      sizeName: data?.sizeName,
      item_id: data.item_id,
      options: Options,
    };
    const finalFoodItem = { ...newFoodItem, ...foodItem };

    const existingItem = cart.find((item) => item?.idItemLocal === finalFoodItem?.idItemLocal);

    const isExist = existingItem?.options?.some((item) => {
      return item?.idToppingLocal === newTopping?.idToppingLocal;
    });

    if (existingItem) {
      //nếu Topping đã có trong giỏ hàng thì ko dc add Topping vào giỏ hàng
      if (!isExist) {
        existingItem.options = [...existingItem?.options, newTopping];
      }
      const newCart = cart.map((item) => {
        //nếu món ăn đã có trong giỏ hàng thì chỉ add đc topping vào giỏ hàng
        if (item.idItemLocal === existingItem.idItemLocal) {
          return existingItem;
        }
        return item;
      });

      setCart(newCart);
    } else {
      setCart([...cart, finalFoodItem]);
    }
  };

  // remove item on table  missing
  const removeItemOnCart = (localId) => {
    const itemCart = cart.filter((item) => {
      return item?.idItemLocal !== localId;
    });
    setQuantityItemIncrease(1);
    setCart(itemCart);
  };

  //======================================================================//

  const removeToppingOnCart = (localToppingId) => {
    const itemTopping = cart?.map((item) => {
      return {
        ...item,
        options: item?.options?.filter((data) => {
          return data?.idToppingLocal !== localToppingId;
        }),
      };
    });
    setCart(itemTopping);
  };
  // Them Mon an
  const onAddItemToCart = (data, index) => {
    const oldQuantity = Number(data.order_q);
    const newQuantity = Number(data.order_q) + 1;

    const updatedData = updateQuantities(data, oldQuantity, newQuantity);

    const newData = {
      ...updatedData,
      order_q: newQuantity,
    };

    const newItem = [...cart];
    newItem[index] = newData;

    setQuantityItemIncrease(newQuantity);
    setCart(newItem);
  };

  // Giam Mon an
  const onSubtractionToCart = (data, index) => {
    const oldQuantity = Number(data.order_q);

    const newQuantity =
      quantityItemIncrease === 0 ? Number(data.order_q) : Number(data.order_q) - 1;

    const updatedData = updateQuantities(data, oldQuantity, newQuantity);

    const newDataSubtractionToCard = {
      ...updatedData,
      order_q: newQuantity,
    };
    const newItem = [...cart];
    newItem[index] = newDataSubtractionToCard;

    setQuantityItemIncrease(newQuantity);
    setCart(newItem);
  };
  //----------------------------------------//

  // them topping
  const onPressAddToping = (dataTopingPlusSubtraction, indexOption, indexToping) => {
    // if (quantityItemIncrease == 0) {
    const quantity = Number(dataTopingPlusSubtraction.quantity) + 1;
    const newDataToping = {
      ...dataTopingPlusSubtraction,
      quantity: quantity,
    };
    const newItem = [...cart];
    if (newDataToping.quantity > 0) {
      newItem[indexOption].options[indexToping] = newDataToping;
    }
    setCart(newItem);
    // }
  };

  // giam topping
  const onPressSubtractionToping = (dataTopingPlusSubtraction, indexOption, indexToping) => {
    const quantity = Number(dataTopingPlusSubtraction.quantity) - 1;
    const newDataTopingSubtraction = {
      ...dataTopingPlusSubtraction,
      quantity: quantity,
    };
    const newItem = [...cart];

    newItem[indexOption].options[indexToping] = newDataTopingSubtraction;

    newItem[indexOption].options = newItem[indexOption].options.filter(
      (item) => item.quantity !== 0
    );

    setCart(newItem);
  };

  //----------------------------------------//

  //Get data
  const getOrderDetail = async (orderId) => {
    try {
      setLoading(true);
      const response = await axios.get(config.API_URL.ORDERS.DETAIL_ORDER(orderId), {
        headers: config.headers,
      });
      setLoading(false);

      const handleId = response?.data?.data?.items?.map((item) => {
        return {
          ...item,
          idItemLocal: generateRandomId(),
          options: item?.options?.map((data) => ({
            ...data,
            idToppingLocal: generateRandomId(),
          })),
        };
      });
      setOrderDataDetail(response?.data?.data);
      setNewItemOrderDataDetail(handleId);
    } catch (error) {
      setLoading(false);
    }
  };

  const handlerModal = (orderId) => {
    setIsModalVisible(!isModalVisible);
    getOrderDetail(orderId);
  };

  const closeModal = () => {
    setIsModalVisible(!isModalVisible);
    setOrderDataDetail({});
    setCart([]);
    setQuantityItemIncrease(0);
  };

  useEffect(() => {
    if (!firstLoadPage) {
      if (!isEmpty(queryString?.filters)) {
        Object.entries(queryString?.filters)?.map(([key, value]) => {
          if (
            [
              'date',
              'isFreeExpress',
              'isDiscount',
              'status',
              'from_company',
              'driver_company',
              'payment',
              'use_geidea',
              'isCasper',
              'isMissingOrder',
            ].includes(key)
          ) {
            if (key === 'date') {
              return (filtersRef.current[key] = newCreateRangeDate(value));
            }
            filtersRef.current[key] = value;
          } else {
            filtersRef.current[key].value = value;
          }
        });
      }
      setFirstLoadPage(true);
    } else {
      getOrders(buildFilterOrderWithRef());
    }
  }, [firstLoadPage, selectedTab]);

  const buildFilterOrderWithRef = (newCurrentPage) => {
    const newFilter = {};
    Object.entries(filtersRef.current).map(([key, valueRef]) => {
      if (key === 'isFreeExpress') {
        return (newFilter[key] = valueRef);
      }
      if (key === 'isDiscount') {
        return (newFilter[key] = valueRef);
      }
      if (key === 'use_geidea') {
        return (newFilter[key] = valueRef);
      }
      if (key === 'isCasper') {
        return (newFilter[key] = valueRef);
      }
      if (key === 'isMissingOrder') {
        return (newFilter[key] = valueRef);
      }
      if (isString(valueRef) || isArray(valueRef)) {
        return (newFilter[key] = valueRef);
      }
      if (
        (isString(valueRef?.value) && valueRef?.value.length > 0) ||
        (isArray(valueRef?.value) && valueRef.value.length > 0)
      ) {
        newFilter[key] = valueRef.value;
      }
    });
    const fullParamFilter = {
      filters: {
        ...newFilter,
      },
      limit: limit,
      page: newCurrentPage ? newCurrentPage : currentPage,
      tab: selectedTab,
    };
    console.log('fullParamFilter>>>>', fullParamFilter);
    return fullParamFilter;
  };

  const handlePageChange = (nextPage) => {
    if (isLoading) return;
    if (Number(nextPage) === Number(currentPage)) return false;
    setCurrentPage(nextPage);
    const newFilters = {
      ...buildFilterOrderWithRef(),
      ...{ page: nextPage },
    };

    getOrders(newFilters);
  };

  const handleLimitChange = (newLimit) => {
    if (isLoading) return;
    setLimit(newLimit);

    const newFilters = {
      ...buildFilterOrderWithRef(),
      ...{ limit: newLimit },
      ...{ page: 1 },
    };

    getOrders(newFilters);
  };

  const onRowClicked = (data) => {
    if (selectedTab !== 'fake_order') return;
    history.push({
      pathname: 'faker-order/drivers',
      search: `?faker-order-id=${data?.order_number}`,
    });
  };

  const buildNewQueryStringWithFilter = (filters) => {
    const cloneDeepFilters = cloneDeep(filters);

    let driver_company = [];
    if (
      isArray(cloneDeepFilters?.filters?.driver_company) &&
      cloneDeepFilters?.filters?.driver_company.length > 0
    ) {
      cloneDeepFilters?.filters?.driver_company.map((item) => driver_company.push(item.value));
      cloneDeepFilters.filters.driver_company = driver_company;
    }
    if (isObject(cloneDeepFilters?.filters?.isDiscount)) {
      cloneDeepFilters.filters.isDiscount = filters.filters.isDiscount.value;
    }
    if (cloneDeepFilters?.filters?.date) {
      cloneDeepFilters.filters.date = createRangeDate(filters?.filters?.date, currentTimezone);
    }
    if (isObject(cloneDeepFilters?.filters?.isFreeExpress)) {
      cloneDeepFilters.filters.isFreeExpress = filters.filters.isFreeExpress.value;
    }
    if (isObject(cloneDeepFilters?.filters?.use_geidea)) {
      cloneDeepFilters.filters.use_geidea = filters.filters.use_geidea.value;
    }
    if (isObject(cloneDeepFilters?.filters?.isCasper)) {
      cloneDeepFilters.filters.isCasper = filters.filters.isCasper.value;
    }
    if (isObject(cloneDeepFilters?.filters?.isMissingOrder)) {
      cloneDeepFilters.filters.isMissingOrder = filters.filters.isMissingOrder.value;
    }
    return cloneDeepFilters;
  };

  async function getOrders(filters = {}) {
    if (isLoading === true) return;
    setIsLoading(true);

    // const filter = parseQueryString(window.location.search);
    const { tab } = filters;
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${buildQueryString({ ...filters })}`;
    window.history.replaceState({ path: newurl }, '', newurl);

    try {
      await axios
        .get(
          `${
            tab !== 'fake_order'
              ? config.API_URL.ORDERS.GET_ORDERS_PAGE
              : config.API_URL.ORDERS.GET_ORDERS_FAKE
          }`,
          {
            params: {
              ...buildNewQueryStringWithFilter(filters),
            },
            headers: config.headers,
          }
        )
        .then(async (response) => {
          setData(response.data.data.results);
          if (response.data.data.results.length > 0) {
            setTotal(response.data.data.total);
          }
        });
      return;
    } catch (e) {
      console.log('Axios error: ', e);
    } finally {
      setIsLoading(false);
    }
  }

  const refresh = () => {
    const newFilters = {
      ...buildFilterOrderWithRef(),
    };
    getOrders(newFilters);
  };

  const handlerGetOrder = useCallback(
    debounce((filters) => getOrders(filters), 500),
    [selectedTab, currentTimezone, isLoading]
  );

  const unAssignOrder = (id_number) => {
    const index = data.findIndex((i) => i._id === id_number);
    const toggle = produce(data, (draft) => {
      draft[index].driver_id = null;
      delete draft[index].driver;
    });
    setData(toggle);
  };

  return {
    selectedTab,
    setSelectedTab,
    selectedItem,
    setSelectedItem,
    onRowClicked,
    handlerGetOrder,
    handlePageChange,
    filtersRef,
    buildFilterOrderWithRef,
    handleLimitChange,
    refresh,
    buildNewQueryStringWithFilter,
    unAssignOrder,

    height,
    refHeight,
    loading,
    missingReOrder,
    totalItemPrice,
    totalQuantityPrice,
    totalResult,
    addItemToCart,
    removeItemOnCart,
    onAddItemToCart,
    handlerModal,
    closeModal,

    cart,
    isModalVisible,
    orderDataDetail,
    quantityItemIncrease,
    newItemOrderDataDetail,
    totalReOrderIsMissing,
    onPressAddToping,
    onSubtractionToCart,
    onPressSubtractionToping,
    setIsModalVisible,
    addTopingItemToCart,
    removeToppingOnCart,
    setCart,
  };
}
