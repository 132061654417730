import { Dialog, Transition } from '@headlessui/react';
import ReOrderDetail from '../../components/Orders/ReOrder/ReOrderDetail';

export default function ReOrderModal({
  isOpen,
  closeModal,
  loading,
  onPressPlus,
  onPressSubtractionToping,
  orderDataDetail,
  cart,
  addItemToCart,
  addTopingToCart,
  onAddItemToCart,
  onSubtractionTopingToCart,
  onPressAddToping,
  onSubtractionToCart,
  removeItemOnCart,
  quantityItemIncrease,
  totalResult,
  totalItemPrice,
  totalQuantityPrice,
  missingReOrder,
  setIsModalVisible,
  isModalVisible,
  addTopingItemToCart,
  newItemOrderDataDetail,
  removeToppingOnCart,
  totalReOrderIsMissing,
  setCart,
  data,
}) {
  return (
    <Transition appear show={isOpen}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        <div className="min-h-screen px-4 text-center rtl">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#00000065]" />
          </Transition.Child>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <ReOrderDetail
              loading={loading}
              closeModal={closeModal}
              onPressPlus={onPressPlus}
              onPressSubtractionToping={onPressSubtractionToping}
              orderDataDetail={orderDataDetail}
              cart={cart}
              addItemToCart={addItemToCart}
              addTopingToCart={addTopingToCart}
              onAddItemToCart={onAddItemToCart}
              onSubtractionTopingToCart={onSubtractionTopingToCart}
              onPressAddToping={onPressAddToping}
              onSubtractionToCart={onSubtractionToCart}
              removeItemOnCart={removeItemOnCart}
              quantityItemIncrease={quantityItemIncrease}
              totalResult={totalResult}
              totalItemPrice={totalItemPrice}
              totalQuantityPrice={totalQuantityPrice}
              missingReOrder={missingReOrder}
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
              addTopingItemToCart={addTopingItemToCart}
              newItemOrderDataDetail={newItemOrderDataDetail}
              removeToppingOnCart={removeToppingOnCart}
              totalReOrderIsMissing={totalReOrderIsMissing}
              setCart={setCart}
              data={data}
            />
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
