export const DAY = 'day';
export const WEEK = 'week';
export const BAR = 'bar';
export const TZ_ASIA_RIYADH = 'Asia/Riyadh';
export const LOCATE_EN = 'en';
export const FORMAT_DATE = 'YYYY-MM-DD';
export const MINUS_ONE = -1;
export const MAX_STAR = 5;
export const MIN_STAR = 0;
export const STEP_STAR = 0.5;

export const ratingStar = (scoreRating) => {
  const checkRatingStar = (number) => `${(scoreRating >= number && '#FCC747') || '#C7C9CE'}`;

  return [
    {
      className: checkRatingStar(1),
      value: 1,
    },
    {
      className: checkRatingStar(2),
      value: 2,
    },
    {
      className: checkRatingStar(3),
      value: 3,
    },
    {
      className: checkRatingStar(4),
      value: 4,
    },
    {
      className: checkRatingStar(5),
      value: 5,
    },
  ];
};

export const renderFields = (text) => {
  if (!text) return '-';
  return <div className="_line-clamp_2">{text}</div>;
};

export const renderRatingColor = (rating) => {
  if (rating < 1) {
    return '#eb2228';
  } else if (rating < 2) {
    return '#fb6838';
  } else if (rating < 3) {
    return '#ffae37';
  } else if (rating < 4) {
    return '#88d54d';
  } else {
    return '#25b53c';
  }
};
